<template>
<div class="card card-height-100">
    <div class="card-body">
        <div class="d-flex mb-2 align-items-center justify-content-start">
            <div class="me-2">
                <div class="avatar img-thumbnail flex-shrink-0">
                    <div class="avatar-title border bg-light text-primary rounded-circle text-uppercase">
                        {{item.name.charAt(0).toUpperCase()}}
                    </div>
                </div>
            </div> 
            <div class="">
                <h5 class="mb-0 card-title">{{item.name}}</h5>
            </div>
        </div>
        <p class="card-text mb-0 small">Gender: <span class="text-uppercase">{{item.gender}}</span></p>
        <p v-if="item.description" class="card-text mt-2 mb-0 small">{{item.description}}</p>
        <hr>
        <slot name="actions"></slot>
    </div>
</div>
</template>

<script>
export default {
    props:{
        item:{
            type: Object,
            required: true
        }
    },      
}
</script>

<style>

</style>