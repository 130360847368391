<template>
    <form autocomplete="off" class="">
        <div class="row gy-3">
            <div class="col-12">
                <label for="inputTitle">
                    Name <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.name"
                    :class="{ 'is-invalid': v$.data.name.$error }"
                    id="inputTitle" name="name" class="form-control"
                    placeholder="Enter name" />
                <div v-for="(item, index) in v$.data.name.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>   
            <div class="col-12">
                <label class="form-label">Gender</label>
                <select class="form-select" v-model="data.gender" :class="{ 'is-invalid': v$.data.gender.$error  }"
                    name="input-gender" placeholder="--select gender--">
                    <option disabled value="" key="-1">-- select gender--</option>
                    <option :value="gender.value" v-for="(gender, index) in genderOptions" 
                    :key="index">{{ gender.label }}</option>
                </select>
                <div v-for="(item, index) in v$.data.gender.$errors"
                :key="index" class="invalid-feedback">
                <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label class="form-label">Description</label>
                <textarea v-model="data.description" class="form-control"
                rows="3" placeholder="Write description" name="description"></textarea>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                name:"",
                gender: "",
                description:"",
            },
            genderOptions:[
                { label: 'Male', value: 'male'},
                { label: 'Female', value: 'female'},
                { label: 'others', value: 'Others'}
            ],
        }
    },
    validations() {
        return {        
            data:{
                name: { required: required}, 
                gender: {
                    required: helpers.withMessage("Select gender", required),
                },           
            },

        }
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                let formData = new FormData();
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post('/judges/create', formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$emit('addItem', response.data.data)
                        this.$store.commit("judge/ADD_ITEM", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/judges/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit("judge/UPDATE_ITEM", response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>